body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  -webkit-transition: 0.25s -webkit-filter linear;
  -moz-transition: 0.25s -moz-filter linear;
  -moz-transition: 0.25s filter linear;
  -ms-transition: 0.25s -ms-filter linear;
  -o-transition: 0.25s -o-filter linear;
  transition: 0.25s filter linear;
}

.ven-theme-dark,
.ven-theme-dark .ant-layout-sider,
.ven-theme-dark .ant-layout-sider-trigger,
.ven-theme-dark .ant-menu,
.ven-theme-dark .ant-layout-header,
.ant-menu-submenu-popup {
  background-color: #1f1f1f !important;
}

.ven-network-main .layout-content {
  margin-top: 64px;
}

.ven-network-test .layout-content {
  margin-top: 86px;
}

@media screen and (max-width: 1024px) {
  .ven-network-main .layout-content {
    margin-top: 40px;
  }
  
  .ven-network-test .layout-content {
    margin-top: 72px;
  }  
}



.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

ul.no-bullets {
  list-style-type: none;
}

.support-multiline {
  white-space: pre-wrap;
}